//  Core

.toggle-switch-square {

  &.rc-switch {
    @include border-radius($border-radius-sm);
  }

  .rc-switch-inner:after {
    @include border-radius($border-radius-sm);
  }
}

.toggle-switch-line {
  &.rc-switch {

    .rc-switch-inner:after {
      border: transparent solid 1px;
      top: 50%;
    }

    &.switch-small {
      height: 8px;

      .rc-switch-inner:after {
        margin-top: -($sw-sm-knob-size / 2);
      }
    }

    &.switch-medium {
      height: 8px;

      .rc-switch-inner:after {
        margin-top: -($sw-md-knob-size / 2);
      }
    }

    &.switch-large {
      height: 8px;

      .rc-switch-inner:after {
        margin-top: -($sw-lg-knob-size / 2);
      }
    }
  }

  &:hover {
    .rc-switch-inner:after {
      border-color: $gray-700;
    }
  }
}
