.nav-buttons {
    .material-icons { 
        cursor: pointer; color: #fff;
        &:hover { color: #61c7c1; } 
    }
}
.theme-light {
    .header {
        background-color: rgb(255, 255, 255);
        .navbar .nav-menu{
            background-color: rgb(255, 255, 255);
            .nav-item {
                &:hover{color: #50d8af;}
                .nav-link{
                    color: rgb(29, 51 ,72);
                    &:hover{color: #50d8af;}
                }
            }
        }

        .nav-buttons {
            .material-icons { 
                 color: rgb(29, 51, 72);
                &:hover { color: #61c7c1; } 
            }
        }
        .login-btn, .free-trail-btn {  
            border: 1px solid rgb(29, 51, 72);
            background-color: rgb(255, 255, 255);
            color: rgb(29, 51, 72);
        }

        .hamburger .bar{ background-color: rgb(29,51 ,72);  }
        .mobile-theme-change-btn {color: rgb(29 ,51 ,72);}
    }
    .sectionA {  
        // background: #1d3348;
        background-image: url(/assets/sectiona_bg.gif), linear-gradient(rgba(255, 255, 255, 0.8), rgba(250, 249, 249, 0.86));
        .text-theme{  
            color: rgb(29, 51, 72);
        }
        .flex-row .btn-outline{  
            border: 1px solid rgb(29, 51, 72);
        }
        .flex-row .button-filled-theme{  
            border: 1px solid rgb(29, 51, 72);
            background-color: rgb(29, 51, 72);
            color: rgb(255, 255, 255);
        }
    }

    .sectionB {
        background-color: #fff;
        .text-theme{  
            color: rgb(29, 51, 72);
        }
        .button-filled-theme{  
            border: 1px solid rgb(29, 51, 72);
            background-color: rgb(29, 51, 72);
            color: rgb(255, 255, 255);
        }
    }

    #sectionC{
        .body{ background-color: #fff;
            h2.text-theme {
                font-size: 20px;
                font-weight: 500;
                color: rgb(29, 51, 72);
            }
            .accordion {
                .accordion-item{
                    .accordion-button{
                        background-color: rgb(255, 255, 255);
                        color: rgb(29, 51, 72);

                        &:not(.collapsed)::after{
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                        }

                        &.collapsed::after{
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                        }
                    }

                    .accordion-body {
                        color: rgb(29, 51, 72);
                        background-color: rgb(255, 255, 255);
                    }
                }
            }

        }
    }
    #sectionD{
        .body{ background-color: #fff;
            .tab {
                .tbSectionDLinks {
                    color: rgb(29, 51, 72);
                }
            }
            .text-theme {
                color: rgb(29, 51, 72);
            }

            .sectionD-anim .stat-box {
                border: 1px solid rgb(255, 255, 255);
                color: rgb(29, 51, 72);
                box-shadow: rgb(29 ,51, 72) 0px 0px 5px 0px;
            }
        }
    }
    #sectionE{
        .body{ background-color: #fff;
            .text-theme {
                color: rgb(29, 51, 72);
            }
        }
    }
    #sectionF{
        .body{ background-color: #fff;
            .text-theme {
                color: rgb(29, 51, 72);
            }
        }
    }
    #sectionI{
        .body{ background-color: #fff;
            .x-card {
                background-color: rgb(29, 51, 72)!important;
            }
        }
    }
    #sectionJ{
        .body{ background-color: #fff;
            .text-theme {
                color: rgb(29, 51, 72);
            }
        }
    }
    #sectionL{
        .body{ background-color: #fff;
            .text-theme {
                color: rgb(29, 51, 72)!important;
            }
        }
    }
    #footer1{
        .body{ background-color: #fff!important;
            .text-theme {
                color: rgb(29, 51, 72)!important;
            }

            .button-filled-theme {
                background-color: rgb(29, 51, 72)!important;
                border: 1px solid rgb(29, 51, 72)!important;
                color: rgb(255, 255, 255)!important;
            }
        }
    }
}

@media (max-width: 800px) {
    .whatisidip_video_container .modal-content { width: 100%; }  
    .whatisidip_video_container .modal-content .modal-body { height: auto!important; }  
}
@media (max-width: 600px) {
    .centerBody {
        width: 100vw;
    }
    #changeText {
        margin-left: 5px;
    }
    .flip-card {
        width: 300px;
        margin: 40px auto;
        padding: 0;
    }
}
