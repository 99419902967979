//  Core

.br-rating-icons-alternate {
  .br-unit {
    font-family: "Pe-icon-7-stroke";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 28px;
    height: 28px;
    font-size: 1.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: $transition-base;
    background-color: transparent !important;

    &:before {
      content: "\e642";
    }
  }
}
