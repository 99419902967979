a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    background: #1D3348;
}

::-webkit-scrollbar-thumb {
    background: #61C7C1;
}

.inputBox {
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    width: 100%;
    outline: none;
    background-color: #1D3348;
    color: white;
    border: 1px solid white;
}

.loginBtn {
    padding: 10px;
    width: 100%;
    background-color: #61C7C1;
    color: white;
    outline: none;
    border: none;
    border-radius: 5px;
}

::placeholder {
    color: rgb(201, 200, 200);
    opacity: 1;
}

.row>* {
    padding: 0px;
}

.checkboxes label {
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
}

.checkboxes input {
    vertical-align: middle;
}

.checkboxes label span {
    vertical-align: middle;
}

.bg_image {
    display: block;
}

@media (max-width:1000px) {
    .bg_image {
        display: none;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.sectionA {
    /**background-image: url(../images/sectiona_bg.gif), linear-gradient(rgba(29, 52, 73, 0.352), rgba(29, 52, 73, 0.104));**/
    background-image: url(/assets/sectiona_bg.gif), linear-gradient(rgba(29, 52, 73, 0.352), rgba(29, 52, 73, 0.104));
    background-blend-mode: overlay;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerBody {
    min-height: 100vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleText {
    color: #fff;
    font-size: 50px;
    font-weight: 600;
    text-align: center;
}

.description {
    -webkit-font-smoothing: antialiased;
    font-family: Poppins;
    -webkit-tap-highlight-color: transparent;
    color: rgb(255, 255, 255);
    font-size: 24px;
    line-height: 1.55;
    text-decoration: none;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    flex-grow: 0;
    padding: 0px 6vw;
}

.btn-outline {
    color: white;
    border: 1px solid #ffffff;
    background-color: transparent;
    padding: 5px 18px;
    font-weight: 600;
    border-radius: 5px;
    margin: 0px 10px;
}

.btn-filled {
    color: #1D3348;
    background-color: white;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 5px 18px;
    font-weight: 600;
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#changeText {
    margin-left: 20px;
}

@media screen and (max-width: 601px) {
    .titleText {
        font-size: 30px;
    }

    .description {
        font-size: 18px;
    }
}

/* what is idip */
.sectionB {
    text-align: center;
    min-height: 100vh;
    /**background-image: url(images/lines_bg.png), linear-gradient(rgba(29, 52, 73, 0.352), rgba(29, 52, 73, 0.104));**/
    background-image: url(/assets/images/lines_bg.png), linear-gradient(rgba(29, 52, 73, 0.352), rgba(29, 52, 73, 0.104));
    background-blend-mode: overlay;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}

.overlay:target {
    visibility: visible;
    opacity: 1;
}

.idipTitle {
    font-size: 40px;
    text-align: center;
    /*font-weight: 600;*/
    font-family: Poppins;
}

.idipContent {
    text-align: justify;
    font-size: 17px;
}

/* get insights */
.fade-in-image {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.accordion-button {
    color: white;
    font-size: 24px;
}

.accordion-button[aria-expanded="true"] {
    color: #61C7C1;
    font-weight: 500;
    background-color: #1D3348;
    padding: 30px;
}

.accordion-button {
    background-color: #1D3348;
    font-weight: 500;
    font-family: "Poppins";
    padding: 30px;
    border: black !important;
}

.accordion-button:focus,
.accordion-button:active {
    stroke-width: 0px;
    background-color: #1D3348;
    border: none !important;
    box-shadow: none !important;
    border-color: black;
}

.accordion-icon {
    color: white;
    background-color: white;
}

.accordion-button.collapsed::after {
    background-image: url(/assets/images/icons/arrow_down.svg);
}

.accordion-button:not(.collapsed)::after {
    background-image: url(/assets/images/icons/arrow_down.svg);
}

.accordion-item {
    background-color: #61C7C1;
}

.accordion-body {
    background-color: #1D3348;
    font-size: 16px;
    padding: 30px;
}

/* roads tabs */
.stat-box {
    background-color: white;
    color: #1D3348;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    margin: 10px;
    min-width: 300px;
    width: 60%;
    max-height: 150px;
}

.stat-count {
    font-size: 40px;
    font-weight: 600;
}

.stat-desc {
    font-size: 18px;
    font-weight: 600;
}

.counternumber {
    color: white;
    border-radius: 8px;
    opacity: 0;
    /* transform: scale(1.2); */
}

@media (prefers-reduced-motion: no-preference) {
    .counternumber {
        transition: opacity 1.5s ease
    }
}

.counternumber-transition {
    opacity: 1;
    transform: none;
}

/* card */
.newsCard {
    cursor: pointer;
    background: PRIMARY_COLOR;
    border: 1px solid #61C7C1;
    border-radius: 10px;
    margin: 10px 10px;
    padding: 10px;
}

/* news card */
.newsCard {
    cursor: pointer;
    color: white;
    background: PRIMARY_COLOR;
    border: 1px solid #61C7C1;
    border-radius: 10px;
    margin: 10px 10px;
    padding: 15px;
}

/* footer */
.nav-logo {
    font-size: 30px;
    font-weight: 600;
    color: #61C7C1;
}

