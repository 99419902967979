//  Core

@each $color, $value in $theme-colors {
  .toggle-switch-#{$color} {
    &.rc-switch.rc-switch-checked {
      background: $value;
      color: color-yiq($value);
      border-color: $value;
    }
  }
}
